<template>
  <div>
    <!-- pic374EC49073F80A46D3D11ED0CB58BAEB.jpg -->
    <img class="h-130 w-200 rounded leftimage" src="@/assets/news-network/pod-4.jpg">
    <p>
      Set to land in Chicago any day now, Pod 4 is eagerly awaited.
    </p>
    <p>
      Each pod and minifig has been different in design and each piece of pod data has revealed four more strange letters. But what do the letters mean? Can they be put together to form a larger message? If so, what is that message?
    </p>
    <p>
      Fortunately, you can help reveal the mystery. When a pod lands and its data is revealed, make sure you translate the message and look for the hidden letters. If you need help or want to show the scientists your work, you can email them: bradfordrant@gmail.com. You may even get your work published on their website!
    </p>
  </div>
</template>
